<template>
  <div v-if="media">
    <div
      v-if="preloaded && !hasErrors"
      ref="mediaViewer"
      :class="customClass"
      class="media-viewer with-player"
    >
      <div
        v-for="(scene, index) in scenes"
        :key="scene.id"
        class="scene-viewer"
        :class="getVisibilityClass(index)"
        :style="sizeSceneStyles(index)"
      >
        <img
          class="scene-bg"
          :src="getAssetFullPath(scene.image)"
          :width="viewer.width+'px'"
          alt="background"
        >
        <media-asset
          v-for="asset in scene.assets"
          :key="asset.id"
          :data="asset"
          :scale="viewer.scale"
          :scene="scene.id"
          :class="asset.classes.join(' ')"
          @cue="assetCue"
          @control="assetControl"
          @click="assetClicked"
        />
      </div>
      <overlay-asset
        :visible="overlayAsset.show"
        :src="overlayAsset.src"
        :scale="overlayAsset.scale"
      />
      <vue-drawing-canvas
        v-if="drawer.enabled"
        ref="drawArea"
        :width="viewer.width"
        :height="viewer.height"
        :color="drawer.color"
        :eraser="drawer.eraser"
        :line-width="drawer.thickness"
        :line-join="drawer.lineJoin"
        :locked="drawer.locked"
        class="draw-area"
        :initial-image="drawer.initialImage"
        background-color="transparent"
        @click="setEnabledDrawing"
        @tap="setEnabledDrawing"
      />
      <drr
        v-for="(item,index) in draggable.items"
        :ref="'draggable_'+item.id"
        :key="index"
        class="draggable-item"
        :class="item.classes.join(' ')"
        :x="item.x"
        :y="item.y"
        :w="item.width"
        :h="item.height"
        :angle="item.angle"
        :aspect-ratio="item.type !== 'text_box'"
        :has-active-content="item.type === 'text_box'"
        @change="(event) => { draggableChanged(item.id, event)}"
        @select="draggableSelected(item.id)"
        @deselect="draggableDeselected(item.id)"
      >
        <template v-if="item.type === 'text_box'">
          <text-box
            :item="item"
            @remove="removeDraggable"
          />
        </template>
        <template v-else>
          <image-box
            :item="item"
            @remove="removeDraggable"
          />
        </template>
      </drr>
      <template v-if="draggable.enabled">
        basura aquí
      </template>
      <text-modal
        v-model="textModal.content"
        :show="textModal.show"
        @close="textModalClose"
      />
      <video-modal
        v-model="videoModal.videoId"
        :show="videoModal.show"
        @close="videoModalClose"
      />
      <audio-player
        v-if="hasAudio || hasCurrentSceneAudio"
        ref="audioPlayer"
        :audio="currentAudio"
        :has-tempo="hasTempo"
        @change-cue="audioPlayerChanged"
        @change-status="audioPlayerStatusChanged"
        @loaded="audioPlayerLoaded"
      />
      <text-toolbar
        v-if="showTextBar"
        :value="selectedDraggable"
        @input="selectedDraggableTextConfigChanged"
      />
    </div>
    <div
      v-if="hasErrors && !preloaded"
      class="error-preloading-container"
    >
      <img
        class="mb-1"
        src="@/assets/img/errors/preloading-error.png"
        :alt="loadingErrorMsg"
      >
      <div class="has-text-centered">
        <p class="mb-1">
          El {{ title }} contiene errores. Lo solucionaremos lo antes posible.
        </p>
        <b-button
          class="is-uppercase"
          type="is-primary"
          @click="goBack"
        >
          Volver
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Mime from 'mime-types'
import MediaAsset from "@/components/medias/MediaAsset";
import AudioPlayer from "@/components/audio/AudioPlayer";
import OverlayAsset from "@/components/medias/OverlayAsset";
import TextModal from "@/components/modals/TextModal";
import VideoModal from "@/components/modals/VideoModal";
import NumberUtils from "@/utils/number"
import AudioUtils from "@/utils/audio"
import DeviceUtils from "@/utils/device"
import VueDrawingCanvas from "vue-drawing-canvas";
import drr from '@minogin/vue-drag-resize-rotate'
import TextBox from "@/components/medias/TextBox";
import ImageBox from "@/components/medias/ImageBox";
import TextToolbar from "@/components/medias/TextToolbar";
import html2canvas from 'html2canvas';

export default {
  name: "MediaViewer",
  components: {
    ImageBox,
    TextBox,
    MediaAsset,
    AudioPlayer,
    OverlayAsset,
    TextModal,
    VideoModal,
    VueDrawingCanvas,
    drr,
    TextToolbar
  },
  props: {
    title: {
      type: String,
      default: 'interactivo'
    },
    data: {
      type: Object,
      default: null
    },
    customClass: {
      type: String,
      default: ''
    },
    sceneIndex: {
      type: Number,
      default: 0,
      validate: (value) => {
        return value === parseInt(value) && value > 0
      }
    }
  },
  data: function () {
    return {
      AUDIO_PLAYER_HEIGHT: 38,
      MAX_WINDOW_WIDTH: 1300,
      MAX_WINDOW_HEIGHT: 800,
      hasErrors: false,
      loaderIt: null,
      media: this.data,
      currentSceneIndex: 0,
      scenes: [],
      preloadList: [],
      preloadIndex: 0,
      preloaded: false,
      preloadedIndex: 0,
      mediaAudio: null,
      timeline: [],
      audioInfo: null,
      audioStatus: 'stop',
      audioControls: [],
      currentFiredAssets: [],
      handlingCancellableAudio: false,
      selectedDraggable: null,
      selectedDraggableIndex: null,
      selectedDraggableRef: null,
      drawer: {
        enabled: false,
        locked: false,
        lineJoin: 'round',
        width: 0,
        height: 0,
        thickness: 6,
        color: 'black',
        eraser: false,
        initialImage: []
      },
      draggable: {
        enabled: false,
        locked: true,
        items: []
      },
      viewer: {
        initial: 1024,
        width: 0,
        height: 0,
        scale: 1,
        tempo: 1
      },
      overlayAsset: {
        show: false,
        src: '',
        scale: 1
      },
      textModal: {
        show: false,
        content: ''
      },
      videoModal: {
        show: false,
        videoId: ''
      }
    }
  },
  computed: {
    loadingErrorMsg() {
      return `Error cargando el ${this.title}`
    },
    INTERNAL_URLS() {
      return this.$store.state.internalUrls
    },
    preparedSceneIndex() {
      if (!this.sceneIndex) {
        return 0
      }
      let index = this.sceneIndex - 1

      if (index < 0) {
        index = 0
      }

      if (index > this.media.sheets.length) {
        index = this.media.sheets.length - 1
      }

      return index
    },
    finishedPreloading() {
      return this.preloadedIndex >= this.preloadList.length
    },
    hasPendingForPreload() {
      return this.preloadIndex < this.preloadList.length
    },
    hasAudio() {
      return this.media.audio !== ''
    },
    hasSceneAudio() {
      return this.scenes && this.scenes.find((scene) => scene.audio !== '') !== null
    },
    hasCurrentSceneAudio() {
      return this.scenes && this.scenes[this.currentSceneIndex].audio !== ''
    },
    hasSceneMagnetizedItems() {
      const magnetAssetIndex = this.scenes[this.currentSceneIndex].assets.findIndex((asset) => asset.has_magnet_behaviour === true)
      return magnetAssetIndex > -1;
    },
    sceneHaveSoundControls() {
      return this.scenes[this.currentSceneIndex].assets.reduce((last, asset) => {
        let assetMatches = asset.behaviours.findIndex((behaviour) => behaviour.key === 'play-pause-on-click' || behaviour.key === 'select-tempo-on-click')

        if (assetMatches === -1) {
          return -1
        }

        return 1
      }) >= 0
    },
    hasAudioUI() {
      return this.media.audio !== '' && !this.sceneHaveSoundControls
    },
    hasTempo() {
      if (!this.media) {
        return false
      }

      return ((this.hasAudio || this.hasCurrentSceneAudio) && this.media.has_tempo === true)
    },
    hasAutoPaginate() {
      if (!this.media) {
        return false
      }

      return this.hasAudio && this.media.has_autopaginate === true
    },
    currentAudio() {
      if (this.hasCurrentSceneAudio) {
        return this.getAssetFullPath(this.scenes[this.currentSceneIndex].audio)
      }

      if (!this.hasCurrentSceneAudio && this.hasAudio) {
        return this.getAssetFullPath(this.media.audio)
      }

      return null
    },
    showTextBar() {
      return this.selectedDraggable && this.selectedDraggable.type === 'text_box'
    },
    hasDraggableItems() {
      return this.draggable.items.length > 0
    },
    draggableAreaClasses() {
      if (this.draggable.locked) {
        return 'no-mouse-events';
      }

      return ''
    }
  },
  watch: {
    data: function (val) {
      if (val !== null) {

        val.sheets = val.sheets.map((scene) => {
          let sceneHasMagnetBehaviour = false

          scene.assets = scene.assets.map((asset) => {
            const magnetIndex = asset.behaviours.findIndex((behaviour) => behaviour.key === 'sticky-area')
            asset.magnet_strength = 0
            asset.has_magnet_behaviour = magnetIndex > -1
            if (!asset?.classes) {
              asset.classes = []
            }
            if (asset.has_magnet_behaviour) {
              sceneHasMagnetBehaviour = true
              asset.magnet_strength = asset.behaviours[magnetIndex]?.data?.distance || 10
              asset.classes.push('magnet-area')
            }

            return asset
          })

          scene.has_magnet_behaviour = sceneHasMagnetBehaviour
          return scene
        })
        this.media = val

        this.resetPreload()

        this.extractPreloadList()

        //avoid bug during loading medias
        this.loaderIt = setInterval(() => {
          if (this.preloadedIndex >= this.preloadList.length && this.preloaded === false) {

            this.preloaded = true
            this.$store.dispatch('finishLoading')

            this.initMediaViewer()

            clearInterval(this.loaderIt)
          }
        }, 200)

        //load simultaneous
        this.preloadContent()
        this.preloadContent()
        this.preloadContent()
        this.preloadContent()
        this.preloadContent()
      }
    },
    sceneIndex(newSceneIndex) {
      const newSceneId = this.scenes[newSceneIndex].id
      this.gotoScene(newSceneId)
    }
  },
  mounted() {
    window.addEventListener('resize', this.windowResized)
  },
  destroyed() {
    window.removeEventListener('resize', this.windowResized)
  },
  methods: {
    getVisibilityClass(index) {
      return (this.currentSceneIndex === index) ? '' : 'hidden'
    },
    getAssetFullPath(assetPath) {
      return process.env.VUE_APP_BASE_CDN + assetPath
    },
    goBack() {
      this.$router.go(-1)
    },
    extractPreloadList() {
      if (!this.media) {
        return
      }

      this.$store.dispatch('initLoading')
      let preloadList = []

      //console.warn(this.media)
      if (this.media.audio !== '') {
        preloadList.push(this.media.audio)
      }

      this.media.sheets.map((scene) => {
        //console.info(scene)
        if (scene.image !== '') {
          preloadList.push(scene.image)
        }

        scene.assets.map((sceneAsset) => {
          if (sceneAsset.type === 'image') {
            preloadList.push(sceneAsset.img)
          }

          sceneAsset.behaviours.map((behaviour) => {
            if (behaviour.hasOwnProperty('data') && behaviour.data.hasOwnProperty('src')) {
              preloadList.push(behaviour.data.src)
            }
          })
          //console.info(sceneAsset)
        })
      })

      this.$store.dispatch('finishLoading')
      preloadList = preloadList.filter((value, index, self) => {
        return self.indexOf(value) === index;
      })

      this.preloadIndex = 0
      this.preloadList = preloadList
    },
    preloadContent() {
      if (this.hasErrors || this.finishedPreloading || !this.hasPendingForPreload) {
        return
      }

      const percentLoaded = Math.round(((this.preloadIndex) / this.preloadList.length) * 100)
      const preloadMsg = 'Cargando contenido (' + percentLoaded + '%)'
      this.$store.dispatch('initLoading', preloadMsg)

      const currentAsset = this.preloadList[this.preloadIndex]
      this.preloadIndex++

      const currentAssetMime = Mime.lookup(currentAsset)

      if (currentAssetMime === 'audio/mpeg') {
        this.preloadAudio(currentAsset)
      }

      if (currentAssetMime === 'image/png' ||
        currentAssetMime === 'image/jpeg' ||
        currentAssetMime === 'image/gif'
      ) {
        this.preloadImage(currentAsset)
      }
    },
    preloadAudio(src) {
      const asset = {
        src: src,
        index: this.preloadIndex
      }

      if (DeviceUtils.isMobileIOS()) {
        //IOS Quirk
        this.preloadedIndex++

        this.contentPreloaded(asset)
        return
      }

      let audio = new Audio();
      // once this file loads, it will call loadedAudio()
      // the file will be kept by the browser as cache
      audio.oncanplaythrough = () => {
        this.contentPreloaded(asset)
      };
      audio.onerror = () => {
        this.onErrorPreloading(asset)
      }

      audio.src = process.env.VUE_APP_BASE_CDN + src;

      //avoid ios portable devices restrictions preloading audio/video elements
    },
    preloadImage(src) {
      const asset = {
        src: src,
        index: this.preloadIndex
      }
      //console.info("preloading image(" + (this.preloadIndex) + "/" + this.preloadList.length + "):" + process.env.VUE_APP_BASE_CDN + src)


      let img = new Image();

      img.onload = () => {
        this.contentPreloaded(asset)
      };
      img.onerror = () => {
        this.onErrorPreloading(asset)
      }

      img.src = process.env.VUE_APP_BASE_CDN + src;
    },
    async contentPreloaded(asset = null) {
      if (this.preloadedIndex < asset.index) {
        this.preloadedIndex = asset.index
      }

      if (this.hasPendingForPreload &&
        !this.hasErrors) {
        this.preloadContent()
      }

      if (this.finishedPreloading) {
        //console.info("~~~~~~~~~ FINISH LOADING ASSETS ~~~~~~~~~")
        if (this.loaderIt) {
          clearInterval(this.loaderIt)
        }
        this.$store.dispatch('finishLoading')
        this.preloaded = true

        this.initMediaViewer()

        /*setTimeout(() => {
          console.warn("manual audioPlayerChanged")
          this.audioPlayerChanged({interval: 0.25, current_time: 5.020183, total_time: 22.124})
        }, 2000)*/
      }
    },
    onErrorPreloading(asset = null) {
      console.error("Error loading media asset", asset)
      this.$store.dispatch('finishLoading')
      this.$emit('error', asset)
      this.hasErrors = true
    },
    initMediaViewer() {
      this.currentSceneIndex = this.preparedSceneIndex
      this.scenes = this.media.sheets
      this.mediaAudio = new Audio()
      this.mediaAudio.onended = this.removeCancellableAudioListener
      setTimeout(() => {
        this.windowResized()
      }, 100)
    },
    assetClicked(event) {
      //console.warn(event)
      //console.info(event.key)

      if (event.key === 'play-sound') {
        const isCancellable = event.data.hasOwnProperty('cancellable') ? event.data.cancellable : false
        const bodyRef = document.body

        if (this.handlingCancellableAudio) {
          bodyRef.removeEventListener('mousedown', this.stopCurrentCancellableAudio)
          this.handlingCancellableAudio = false
        }

        this.mediaAudio.pause()
        this.mediaAudio.src = this.getAssetFullPath(event.data.src)
        this.mediaAudio.play()

        if (isCancellable) {
          bodyRef.addEventListener('mousedown', this.stopCurrentCancellableAudio)
          this.handlingCancellableAudio = true
        }
      }

      if (event.key === 'open-text-modal') {
        this.textModal.content = event.data.content
        this.textModal.show = true
      }

      if (event.key === 'open-video-modal') {
        this.videoModal.videoId = event.data.video_id
        this.videoModal.show = true
      }

      if (event.key === 'open-url') {

        const targetURL = event.data.url

        if (this.isInternalURL(targetURL)) {
          if (event.data.target === '_self') {
            window.location = event.data.url
          }

          if (event.data.target === '_blank') {
            window.open(event.data.url)
          }
        }

        if (!this.isInternalURL(targetURL)) {
          this.$buefy.dialog.confirm({
            message: 'Estás a punto de abrir la dirección <b>' + event.data.url + '</b>',
            cancelText: 'Cancelar',
            confirmText: 'Continuar',
            onConfirm: () => {
              if (event.data.target === '_self') {
                window.location = event.data.url
              }

              if (event.data.target === '_blank') {
                window.open(event.data.url)
              }
            }
          })
        }
      }

      if (event.key === 'open-scene') {
        this.gotoScene(event.data.scene_id)
      }

      if (event.key === 'open-random-scene') {
        const newSceneIndex = NumberUtils.getRandomNumber(0,this.scenes.length-1, [this.currentSceneIndex])
        const sceneId = this.scenes[newSceneIndex].id
        this.gotoScene(sceneId)
      }

      if (event.key === 'highligth-on-click') {
        const eventData = event.data
        //console.info(eventData.type)

        if (eventData.type === 'replace') {
          eventData.setActive(event)

          const replaceDuration = AudioUtils.getPlaybackRatedDuration(this.viewer.tempo, parseInt(eventData.duration))

          clearTimeout(this.overlayAssetInt)
          this.overlayAssetInt = setTimeout(() => {
            eventData.setInactive(event)
          }, replaceDuration)
        }

        if (eventData.type === 'center') {
          this.overlayAsset.src = eventData.src
          this.overlayAsset.scale = (parseInt(eventData.size) / 100) * this.viewer.scale
          this.overlayAsset.show = true

          const overlayDuration = AudioUtils.getPlaybackRatedDuration(this.viewer.tempo, parseInt(eventData.duration))

          clearTimeout(this.overlayAssetInt)
          this.overlayAssetInt = setTimeout(() => {
            this.overlayAsset.show = false
          }, overlayDuration)
        }
      }

      if (event.key === 'play-pause-on-click') {
        if (this.$refs.audioPlayer) {
          this.$refs.audioPlayer.playClicked()
        }
      }

      if (event.key === 'select-tempo-on-click') {
        if (this.$refs.audioPlayer) {
          this.$refs.audioPlayer.showTempoSelector()
        }
      }
    },
    assetCue(timeEvent) {
      this.timeline.push(timeEvent)
    },
    assetControl(controlEvent) {
      this.audioControls.push(controlEvent)
    },
    audioPlayerLoaded(info) {
      this.audioInfo = info

      this.prepareTimeline()
    },
    audioPlayerStatusChanged(status) {
      this.audioStatus = status
      const targetStatus = status === 'play' ? 'playing' : 'paused'

      for (const key in this.audioControls) {
        const controlEvent = this.audioControls[key]

        if (targetStatus === 'playing') {
          controlEvent.setActive(controlEvent.data)
        }

        if (targetStatus === 'paused') {
          controlEvent.setInactive(controlEvent.data)
        }
      }

      this.$emit('audio-status-changed', {status})
    },
    audioPlayerChanged(cueEvent) {
      //console.info("##############" + cueEvent.current_time + "################")
      //console.info(cueEvent)
      const timeEvents = this.timeline.length
      const firedAssetList = []

      this.viewer.tempo = cueEvent.playback_rate
      //console.info(this.viewer.tempo+"<<<<tempo")

      for (let i = 0; i < timeEvents; i++) {
        const timeItemEvent = this.timeline[i]

        const isEventFired = NumberUtils.isBetweenRange(cueEvent.current_time, timeItemEvent.start, timeItemEvent.finish)
        //console.info(cueEvent.current_time, timeItemEvent.start, timeItemEvent.finish, isEventFired)

        if (!isEventFired) {
          const assetIsFiredPrev = firedAssetList.findIndex((id) => id === timeItemEvent.id) !== -1
          //console.warn("evento lanzado anteriormente")

          if (assetIsFiredPrev) {
            //console.info("Event avoid disabled " + timeItemEvent.id)
            //avoid desactive asset previous activated in this event
            continue;
          }

          const hasFiredAsset = this.currentFiredAssets.hasOwnProperty(timeItemEvent.id)

          if (hasFiredAsset) {
            //console.info("desactiva asset previamente habilitado", timeItemEvent.id)
            if (timeItemEvent.type === 'center') {
              //console.info("Oculta overlay")
              this.overlayAsset.show = false
            }

            if (timeItemEvent.type === 'replace' || timeItemEvent.type === 'overlap') {
              timeItemEvent.setInactive(timeItemEvent.data)
            }

            delete this.currentFiredAssets[timeItemEvent.id]
          }
        }

        if (isEventFired) {

          /*if (timeItemEvent.type !== 'scene') {
            console.info("Event fired " + timeItemEvent.id + " -> " + timeItemEvent.type)
          }*/
          //iteration checks
          firedAssetList.push(timeItemEvent.id)
          //global checks
          this.currentFiredAssets[timeItemEvent.id] = timeItemEvent

          if (timeItemEvent.type === 'center') {
            const eventData = timeItemEvent.data
            this.overlayAsset.src = eventData.data.src
            this.overlayAsset.scale = parseInt(eventData.data.size) / 100
            this.overlayAsset.show = true
          }

          if (timeItemEvent.type === 'replace' || timeItemEvent.type === 'overlap') {
            timeItemEvent.setActive(timeItemEvent.data)
          }

          if (timeItemEvent.type === 'scene') {
            this.gotoScene(timeItemEvent.data.sceneId)
          }
        }

        this.$emit('audio-cue-changed', {time: cueEvent.current_time, playback_rate: cueEvent.playback_rate})
      }
    },
    gotoScene(sceneId) {
      let sceneIndex = this.scenes.findIndex((item) => item.id === sceneId)

      if (sceneId === 'next') {
        sceneIndex = this.currentSceneIndex + 1
        if (sceneIndex + 1 >= this.scenes.length) sceneIndex = this.scenes.length - 1
      } else if (sceneId === 'previous') {
        sceneIndex = this.currentSceneIndex - 1
        if (sceneIndex < 0) sceneIndex = 0
      }

      if (sceneIndex >= 0 &&
        this.currentSceneIndex !== sceneIndex) {

        if (this.hasCurrentSceneAudio) {
          this.$refs.audioPlayer.stopAudio()
        }

        this.currentSceneIndex = sceneIndex
      }
    },
    textModalClose() {
      this.textModal.show = false
      this.textModal.content = ''
    },
    videoModalClose() {
      this.videoModal.show = false
      this.videoModal.videoId = ''
    },
    prepareTimeline() {
      if (this.hasAutoPaginate) {

        this.scenes.map((scene, index) => {

          const timeEvent = {
            id: scene.id,
            data: {sceneId: scene.id},
            start: scene.cue / 1000,
            finish: this.getSceneEnd(index),
            type: 'scene'
          }

          this.timeline.push(timeEvent)
        })
      }
    },
    getSceneEnd(index) {
      const nextSceneIndex = index + 1

      if (nextSceneIndex >= this.scenes.length) {
        return this.audioInfo.totalTime
      }

      const nextScene = this.scenes[nextSceneIndex]

      return (nextScene.cue - 1) / 1000
    },
    stopAll() {
      const audioPlayerRef = this.$refs.audioPlayer

      if (audioPlayerRef) {
        audioPlayerRef.stopAudio()
      }

      if (this.mediaAudio) {
        this.mediaAudio.pause()
        this.mediaAudio.currentTime = 0
      }
    },
    getScale() {
      return this.viewer.width / this.viewer.initial
    },
    async windowResized() {
      try {
        await this.$nextTick()
        //this.vhSafariFix()
        let viewerWidth = this.$refs.mediaViewer.clientWidth
        let viewerHeight = this.$refs.mediaViewer.clientHeight
        //DISABLED WIDTH/HEIGHT LIMIT (TESTING DYNAMIC)
        //viewerWidth = (viewerWidth > this.MAX_WINDOW_WIDTH) ? this.MAX_WINDOW_WIDTH : viewerWidth
        //viewerHeight = (viewerWidth > this.MAX_WINDOW_HEIGHT) ? this.MAX_WINDOW_HEIGHT : viewerWidth

        this.viewer.width = viewerWidth
        this.viewer.height = viewerHeight
        this.viewer.scale = this.getScale()
      } catch (error) {
        console.warn("No ha sido posible obtener las dimensiones del viewport")
      }
    },
    sizeSceneStyles(index) {
      const scene = this.media.sheets[index]

      let targetWidth = this.viewer.width
      let targetScale = targetWidth / scene.width
      let targetHeight = Math.round(scene.height * targetScale)
      let viewerAvailableHeight = this.viewer.height

      if (this.hasAudio || this.hasCurrentSceneAudio) {
        viewerAvailableHeight -= this.AUDIO_PLAYER_HEIGHT
      }

      if (targetHeight > viewerAvailableHeight) {
        targetHeight = viewerAvailableHeight
        let newScale = targetHeight / scene.height

        targetWidth = Math.round(scene.width * newScale)
        if (index === this.currentSceneIndex) {
          this.viewer.scale = targetHeight / scene.height
        }
      }
      //console.warn(`${index} size styles`, `width: ${targetWidth}px; height: ${targetHeight}px;`)
      return `width: ${targetWidth}px; height: ${targetHeight}px;`
    },
    resetPreload() {
      this.hasErrors = false
      this.preloadIndex = 0;
      this.preloadedIndex = 0;
    },
    isInternalURL(url) {
      const match = this.INTERNAL_URLS.find((internalURL) => url.indexOf(internalURL) === 0)

      return !!match;
    },
    stopCurrentCancellableAudio() {
      this.mediaAudio.pause()
      if (this.overlayAsset.show) {
        clearTimeout(this.overlayAssetInt)
        this.overlayAsset.show = false
      }
      this.removeCancellableAudioListener()
    },
    removeCancellableAudioListener() {
      if (this.handlingCancellableAudio) {
        const bodyRef = document.querySelector('body')

        if (bodyRef) {
          bodyRef.removeEventListener('mousedown', this.stopCurrentCancellableAudio)
          this.handlingCancellableAudio = false
        }
      }
    },
    toggleAudio() {
      if (!this.hasAudio && !this.hasSceneAudio) {
        return
      }

      this.$refs.audioPlayer.playClicked()
    },
    stopAudio() {
      if (!this.hasAudio) {
        return
      }

      this.$refs.audioPlayer.stopClicked()
    },
    setDrawConfig(config) {
      if (config.hasOwnProperty('color') && config.color) {
        this.drawer.color = config.color
      }

      if (config.hasOwnProperty('thickness') && config.thickness) {
        this.drawer.thickness = config.thickness
      }

      if (config.hasOwnProperty('eraser')) {
        this.drawer.eraser = !!config.eraser
      }

      if (config.hasOwnProperty('locked')) {
        this.drawer.locked = !!config.locked
        this.draggable.enabled = (!this.drawer.enabled) && this.data.has_drawer
      }

      if (config.hasOwnProperty('enabled')) {
        this.drawer.enabled = (!!config.enabled) && this.data.has_drawer
      }

      if (config.hasOwnProperty('initialImage') && Array.isArray(config.initialImage)) {
        this.drawer.initialImage = JSON.parse(JSON.stringify(config.initialImage))
      }

    },
    setDraggableConfig(config) {
      if (config.hasOwnProperty('locked')) {
        this.draggable.locked = !!config.locked
      }

      if (config.hasOwnProperty('enabled')) {
        this.draggable.enabled = !!config.enabled
      }
    },
    getDrawPoints() {
      if (!this.$refs?.drawArea) {
        return []
      }
      return this.$refs.drawArea.getAllStrokes()
    },
    resetDraw() {
      this.$buefy.dialog.confirm({
        message: 'Vas a <b>Borrar</b> todos los trazos que has dibujado. ¿Deseas continuar?',
        cancelText: 'Cancelar',
        confirmText: 'Continuar',
        onConfirm: () => {
          this.$refs.drawArea.reset()
        }
      })
    },
    addDraggables(list) {
      list.forEach((item) => {
        this.addDraggable(item)
      })
    },
    addDraggable(item) {
      if (!item.hasOwnProperty('classes')) {
        item.classes = []
      }

      if (item.type === 'text_box' && (!item?.textConfig)) {
        item.text = 'Dame dos veces para editarme'
        item.textConfig = {
          align: 'center',
          color: 'black',
          family: '"Work Sans", sans-serif',
          size: '22px',
          backgroundColor: '#FFFFFF'
        }
      }

      item.config = {
        x: item.x,
        y: item.y,
        w: item.width,
        h: item.height,
        angle: item.angle,
        classes: item.classes || []
      }

      item.selected = false
      this.draggable.items.push(item)
      this.drawer.locked = true
      this.draggable.locked = false
    },
    removeDraggable(item) {
      const itemId = item.id
      const foundIndex = this.draggable.items.findIndex(item => item?.id === itemId)
      if (foundIndex < 0) {
        return
      }

      this.draggable.items.splice(foundIndex, 1)
    },
    draggableChanged(id, event) {
      const foundIndex = this.draggable.items.findIndex(item => item.id === id)
      if (foundIndex < 0 || !event) {
        console.warn("Draggable modificado no encontrado")
        return
      }

      this.draggable.items[foundIndex].config = event
      this.draggable.items[foundIndex].x = event.x
      this.draggable.items[foundIndex].y = event.y

      const scene = this.scenes[this.currentSceneIndex]

      if (!scene.has_magnet_behaviour) {
        return
      }

      const draggedItem = {index: foundIndex, x: event.x, y: event.y}
      const magnetizedAssets = scene.assets.filter((asset) => asset.has_magnet_behaviour === true)

      const distances = magnetizedAssets.map((asset) => {
        const mediaViewerRect = this.$refs.mediaViewer.getBoundingClientRect()
        const sceneViewerRect = document.querySelector('.scene-viewer:not([style=\'display:none\'])').getBoundingClientRect()
        const xOffset = mediaViewerRect.width - sceneViewerRect.width
        const yOffset = mediaViewerRect.height - sceneViewerRect.height
        const magnetizedPoint = {
          x: ((asset.x + (asset.width / 2)) * this.viewer.scale)+xOffset,
          y: ((asset.y + (asset.height / 2)) * this.viewer.scale)+yOffset
        }

        const distance = Math.hypot(draggedItem.x - magnetizedPoint.x, draggedItem.y - magnetizedPoint.y)
        console.info(asset.id, distance, '<=', asset.magnet_strength)
        return {
          id: asset.id,
          is_attracted: distance <= asset.magnet_strength,
          strength: asset.magnet_strength,
          distance,
          x: magnetizedPoint.x,
          y: magnetizedPoint.y
        }
      })

      let nearestTarget = null
      console.warn("--- check magnet proximity ---")
      distances.forEach((item) => {
        if (item.is_attracted && (!nearestTarget || nearestTarget.distance < item.distance)) {
          console.warn("Encuentra elemento cercano")
          nearestTarget = item
        }
      })

      console.warn(nearestTarget)
      if (nearestTarget) {
        //llevar a ese punto
        console.error("colision detectada")

        /*if (!this.draggable.items[foundIndex].classes.includes('animated')) {
          this.draggable.items[foundIndex].classes.splice(0, 0, 'animated')
          setTimeout(() => {
            console.error("elimina animated!!!!!")
            console.info(this.draggable.items[foundIndex].classes)
            if (this.draggable.items[foundIndex].classes.length > 0 && this.draggable.items[foundIndex].classes[0] === 'animated') {
              this.draggable.items[foundIndex].classes.splice(0, 1)
            }
          }, 600)
        }*/
        this.draggable.items[foundIndex].config.x = nearestTarget.x
        this.draggable.items[foundIndex].config.y = nearestTarget.y
        this.draggable.items[foundIndex].x = nearestTarget.x
        this.draggable.items[foundIndex].y = nearestTarget.y
      }

    },
    draggableAreaClick(e) {
      this.setEnabledDrawing(e)
    },
    getDraggableItems() {

      return this.draggable.items.map((item) => {
        item.x = item?.config?.x || item.x
        item.y = item?.config?.y || item.y
        item.width = item?.config?.w || item.width
        item.height = item?.config?.h || item.height
        item.angle = item?.config?.angle || item.angle

        delete item.config

        return item
      })
    },
    getDimensions() {
      return {width: this.viewer.width, height: this.viewer.height, scale: this.viewer.scale}
    },
    setEnabledDrawing(e) {
      const draggableCollides = this.hasDraggableCollission(e)

      this.drawer.locked = !draggableCollides
      this.draggable.locked = draggableCollides
    },
    hasDraggableCollission(event) {
      return true
    },
    draggableSelected(itemId) {
      //console.info("Selected", itemId)
      const foundIndex = this.draggable.items.findIndex(item => item?.id === itemId)
      if (foundIndex < 0) {
        return
      }
      if(this.selectedDraggableRef && itemId !== this.selectedDraggable?.id){
        this.selectedDraggableRef.deselect()
      }

      this.selectedDraggableRef = this.$refs['draggable_'+itemId][0]
      this.selectedDraggable = this.draggable.items[foundIndex].type === 'text_box' ? {...this.draggable.items[foundIndex]} : null
      this.selectedDraggableIndex = null

      this.draggable.items[foundIndex].selected = true
      //console.info("marca seleccionado", {...this.draggable.items[foundIndex]})
    },
    draggableDeselected(itemId) {
      //console.info("Deselected", itemId)
      const foundIndex = this.draggable.items.findIndex(item => item?.id === itemId)

      if (foundIndex < 0) {
        return
      }

      //this.selectedDraggable = null
      this.draggable.items[foundIndex].selected = false
    },
    async saveToImg(fileName = null) {
      try {
        if (!fileName) {
          let now = new Date();
          fileName = now.getTime() + "-" + this.data.slug + '.png';
        }

        let html2canvasOptions = {
          width: this.$refs.mediaViewer.offsetWidth,
          height: this.$refs.mediaViewer.offsetHeight,
          ignoreElements: (element) => {
            return element.classList.contains('text-toolbar');
          },
          allowTaint: true,
          useCORS: true,
          imageTimeout: 5000,
          logging: false
        };

        const createdCanvas = await html2canvas(this.$refs.mediaViewer, html2canvasOptions)
        let imageData = createdCanvas.toDataURL();

        const link = document.createElement('a')
        link.href = imageData
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      } catch (e) {
        console.error("Error al crear el canvas")
        console.error(e)
      }
    },
    selectedDraggableTextConfigChanged(textConfig) {

      if (this.selectedDraggableIndex === null) {
        this.selectedDraggableIndex = this.draggable.items.findIndex((draggable) => draggable.id === this.selectedDraggable.id)
      }
      //console.warn({...this.draggable.items[this.selectedDraggableIndex]})
      this.draggable.items[this.selectedDraggableIndex].textConfig = textConfig
      this.selectedDraggable.textConfig = textConfig
    }
  }
}
</script>
