<template>
  <div
    class="image-container"
  >
    <a
      v-if="itemIsActive"
      class="remove-btn"
      href="#"
      @click="$emit('remove',item)"
    ><b-icon
      pack="fas"
      icon="times"
      size="is-small"
    />
    </a>
    <img
      class="image"
      :src="item.image"
      :alt="item.title"
    >
  </div>
</template>

<script>
export default {
  name: 'ImageBox',
  props: {
    item: {
      type: Object,
      default: () => {
        return null
      }
    }
  },
  computed: {
    itemIsActive(){
      return this.item.selected === true
    }
  },
  mounted() {
    this.$on('active', this.onActive)
  }
}
</script>

<style scoped lang="scss">
.image-container{
  position: relative;

  .remove-btn{
    position: absolute;
    right: 15px;
    top: 0;
    transform: translateY(-50%);
    background-color: darkred;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

</style>
